import { extend, localize } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';

export default () => {
  localize('fr');
  extend('required', {
    ...required,
    message: 'Ne peut être vide',
  });
  extend('email', {
    ...email,
    message: 'Doit être une adresse e-mail valide',
  });
};
