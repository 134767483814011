
import _ from 'lodash';
import Component, { mixins } from 'vue-class-component';
import Site from '@/shared/store/models/Site';
import WithStateMixin from '@/shared/store/WithStateMixin';
import { Watch } from 'vue-property-decorator';
import HttpMixin from '@/shared/http/http.mixin';
import Form from '@/shared/store/models/Form';
import { Mutations } from '@/shared/store/mutations';
import FormDialog from '@/shared/forms/components/FormDialog.vue';
import SiteSelection from '../components/SiteSelection.vue';
import SiteSelectionDialog from '../components/SiteSelectionDialog.vue';
import WithSiteMixin from '../mixins/with-site.mixin';
import { siteTools } from '../site.tools';

@Component({
  components: { SiteSelectionDialog, SiteSelection, FormDialog },
})
export default class SiteHome extends mixins(
  HttpMixin,
  WithSiteMixin,
  WithStateMixin,
) {
  forms: { [groupName: string]: Form[] } = {};
  form: Form | false = false;
  formDialog = false;

  async mounted() {
    if (this.site) this.loadForms(this.site);
  }

  @Watch('site')
  async siteChange(newSite: Site, oldSite: Site) {
    if (!oldSite || newSite.customerId !== oldSite.customerId)
      this.goToSite(newSite);
    this.loadForms(newSite);
  }

  openForm(form: Form) {
    this.form = form;
    this.formDialog = true;
  }

  async loadForms(site: Site) {
    this.startLoading();
    document.title = `${site.customerId}: ${site.name} - MyPlay`;
    return this.http
      .loadSiteForms(site.customerId)
      .then((forms) => (this.forms = _.groupBy(forms, 'group')))
      .finally(() => this.endLoading());
  }

  changeSiteSelection(site: Site) {
    this.$store.commit(Mutations.SITE_SELECT, site);
  }

  goToSite(site: Site) {
    this.$router.push(`/saisie/${siteTools.makeSiteSlug(site)}`);
  }
}
