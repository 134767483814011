
import Vue from 'vue';
import { SnackType } from '@/shared/app/app.constants';
import Component from 'vue-class-component';
import BaseEslForm from '../base-esl-form';
import { EslDishesFormPayload } from './esl-dishes-form.payload';

@Component
export default class EslDishesForm extends BaseEslForm {
  valid = false;
  loading = false;

  payload: EslDishesFormPayload = {};

  async submit() {
    this.loading = true;
    await this.http.pushPayloadToEsl(this.eslConfig, this.payload).then(
      () => {
        this.snack({ text: 'Mise à jour en cours', type: SnackType.SUCCESS });
      },
      () => {
        this.snack({
          text: "Échec de la mise à jour de l'étiquette",
          type: SnackType.ERROR,
        });
      },
    );
    this.loading = false;
  }

  addDish() {
    if (!this.canAddDish) return;
    if (!this.payload.dishes) Vue.set(this.payload, 'dishes', []);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.payload.dishes!.push({ name: '', price: 0.0 });
  }

  get maxDishes() {
    return Number(this.eslConfig.formConfig.maxDishes) || 12;
  }

  delDish(index: number) {
    if (this.payload.dishes && index < this.payload.dishes?.length)
      this.payload.dishes.splice(index, 1);
  }

  get canAddDish() {
    return (this.payload.dishes || []).length < this.maxDishes;
  }

  get dishesLen() {
    return this.payload.dishes?.length || 0;
  }

  nameRules = [
    (v: string) =>
      (v && v.length > 2 && v.length < 131) || 'Le nom doit comporter entre 3 et 130 caractères',
  ];

  headerRules = [
    (v: string) =>
      !Boolean(v?.length) ||
      (v && v.length > 2 && v.length < 25) ||
      "L'en-tête doit comporter entre 3 et 24 caractères",
  ];

  hiddenRules = [(v: boolean) => v === true];

  get hiddenValue() {
    return Boolean((this.payload.dishes || []).length || this.payload.header);
  }
}
