
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import WithSiteMixin from '@/shared/sites/mixins/with-site.mixin';
import Form from '@/shared/store/models/Form';
import WithStateMixin from '@/shared/store/WithStateMixin';
import { Prop, Watch } from 'vue-property-decorator';

@Component
export default class BaseForm extends mixins(
  Vue,
  WithSiteMixin,
  WithStateMixin,
) {
  @Prop()
  form!: Form;

  @Prop({ type: Boolean })
  dialog!: boolean;

  mounted() {
    if (this.dialog) this.activate();
  }

  @Watch('dialog')
  dialogWatcher(value: boolean) {
    if (value && typeof this.activate === 'function') this.activate();
  }

  activate() {
    //
  }

  emitLoading(state: boolean) {
    this.$emit('loading', state);
  }
}
