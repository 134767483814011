
import { SnackType } from '@/shared/app/app.constants';
import Component from 'vue-class-component';
import BaseEslForm from '../base-esl-form';
import { EslProductFormPayload } from './esl-product-form.payload';

@Component
export default class EslProductForm extends BaseEslForm {
  valid = false;
  loading = false;

  payload: EslProductFormPayload = {};

  async submit() {
    this.loading = true;
    await this.http.pushPayloadToEsl(this.eslConfig, this.payload).then(
      () => {
        this.snack({ text: 'Mise à jour en cours', type: SnackType.SUCCESS });
      },
      () => {
        this.snack({
          text: "Échec de la mise à jour de l'étiquette",
          type: SnackType.ERROR,
        });
      },
    );
    this.loading = false;
  }

  nameRules = [
    (v: string) => Boolean(v) || 'Le nom du produit est obligatoire',
    (v: string) =>
      (v && v.length > 2 && v.length < 121) || 'Le nom doit comporter entre 3 et 120 caractères',
  ];

  readonly nutriscores = [
    {
      text: 'Non renseigné',
      value: undefined,
    },
    {
      text: 'A',
      value: 'a',
    },
    {
      text: 'B',
      value: 'b',
    },
    {
      text: 'C',
      value: 'c',
    },
    {
      text: 'D',
      value: 'd',
    },
    {
      text: 'E',
      value: 'e',
    },
  ];

  readonly allergens = [
    'Arachide',
    'Céleri',
    'Crustacés',
    'Gluten',
    'Fruits à coque',
    'Lait',
    'Lupin',
    'Œuf',
    'Poisson',
    'Mollusques',
    'Moutarde',
    'Sésame',
    'Soja',
    'Sulfites',
  ];
}
