
import { EslConfig } from '@/shared/store/models/EslConfig';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class EslList extends Vue {
  @Prop({
    default() {
      return [];
    },
  })
  eslConfigs!: EslConfig[];
}
