import { Nullable } from '@/shared/app/app.utils';
import WithSnackMixin from '@/shared/app/mixins/with-snack.mixin';
import HttpMixin from '@/shared/http/http.mixin';
import { EslConfig } from '@/shared/store/models/EslConfig';
import { EslInfo } from '@/shared/store/models/EslInfo';
import Vue from 'vue';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { EslPayload } from '../esl-form.utils';

@Component
export default class BaseEslForm extends mixins(Vue, HttpMixin, WithSnackMixin) {
  @Prop()
  eslConfig!: EslConfig;

  @Prop()
  eslInfo!: EslInfo;

  payload: EslPayload = {};

  mounted() {
    this.eslInfoWatcher(this.eslInfo);
  }

  @Watch('eslInfo')
  eslInfoWatcher(eslInfo: Nullable<EslInfo>) {
    if (eslInfo && eslInfo.lastPayload) this.payload = eslInfo.lastPayload;
  }
}
