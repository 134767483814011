import Vue from 'vue';
import Component from 'vue-class-component';
import { httpService } from './http.service';

@Component
export default class HttpMixin extends Vue {
  protected http: typeof httpService;

  constructor() {
    super();
    this.http = httpService;
  }
}
