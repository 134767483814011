import Vue from 'vue';
import Component from 'vue-class-component';
import { loadingService } from '.';
import InitialState from './InitialState';

@Component
export default class WithStateMixin extends Vue {
  get state(): InitialState {
    return this.$store.state;
  }

  get theme() {
    return this.state.theme;
  }

  startLoading() {
    loadingService.start();
  }

  endLoading() {
    loadingService.end();
  }

  get loading() {
    return this.state.loading === true;
  }
}
