import Site from '@/shared/store/models/Site';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class WithSiteMixin extends Vue {
  get site(): Site | undefined {
    return this.$store.state.selectedSite;
  }
}
