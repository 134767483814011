import Axios from 'axios';
import { AppConstants } from '../app/app.constants';

const axios = Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
});

axios.interceptors.request.use((config) => {
  if (localStorage[AppConstants.LOCAL_STORAGE_TOKEN_KEY])
    config.headers.Authorization = `Bearer ${localStorage.token}`;
  return config;
});

export default axios;
