import Site from '@/shared/store/models/Site';
import Vue from 'vue';
import Component from 'vue-class-component';
import { SnackOptions } from '../app.constants';

@Component
export default class WithSnackMixin extends Vue {
  snack(opts: SnackOptions) {
    this.$root.$emit('snack', opts);
  }
}
