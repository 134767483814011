import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import Theme from '../model/Theme';

Vue.use(Vuetify);

export default (theme: Theme) =>
  new Vuetify({
    theme: {
      themes: {
        light: {
          primary: theme.primaryColor,
          secondary: theme.secondaryColor,
          accent: theme.accentColor,
          error: theme.errorColor,
          warning: theme.warningColor,
          info: theme.infoColor,
          success: theme.successColor,
        },
      },
    },
  });
