
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import HttpMixin from '@shared/http/http.mixin';
import WithStateMixin from '@/shared/store/WithStateMixin';
import { Mutations } from '@/shared/store/mutations';
import { AppEvents, SnackOptions, SnackType } from '../app.constants';
import AppBottomMenu from './AppBottomMenu.vue';
import Snack from './Snack.vue';

@Component({
  components: {
    AppBottomMenu,
    Snack,
  },
})
export default class App extends mixins(Vue, HttpMixin, WithStateMixin) {
  private drawer = false;
  private appBoostrapped = false;

  beforeMount() {
    this.boostrap();
    this.$root.$on(AppEvents.REQUEST_BOOSTRAP, this.boostrap);
  }

  beforeDestroy() {
    this.$root.$off(AppEvents.REQUEST_BOOSTRAP, this.boostrap);
  }

  boostrap() {
    /*
     *  App bootstraping
     */
    this.startLoading();
    this.http
      .getMyUser()
      .then(
        (user) => {
          this.$store.commit(Mutations.USER_SET, user);
          if (this.$router.currentRoute.name === 'login') {
            this.$router.push({ path: '/' });
          }
          if (this.$router.currentRoute.name === 'error') {
            this.$router.push({ path: '/' });
          }
        },
        (e) => {
          const status = e.response?.status;
          if (status === 401) {
            this.logout();
          } else {
            this.$router.push('error');
          }
          throw e;
        },
      )
      .finally(() => {
        this.endLoading();
        this.appBoostrapped = true;
      });
  }

  mounted() {
    this.drawer = this.$vuetify.breakpoint.lgAndUp;
  }

  get authenticated() {
    return Boolean(this.user);
  }

  get user() {
    return this.state.user;
  }

  logout() {
    localStorage.clear();
    this.$store.commit(Mutations.USER_SET, null);
    this.goToLogin();
  }

  goToLogin() {
    if (this.$router.currentRoute.name !== 'login') {
      this.$router.push({ name: 'login' });
    }
  }
}
