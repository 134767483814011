import User from './models/User';
import Collection from './models/Collection';
import Form from './models/Form';
import Site from './models/Site';
import List from './models/List';
import Communication from './models/Communication';
import Theme from '../app/model/Theme';
import defaultTheme from '../app/theme/default.theme';
import CustomerConfig from '../app/model/CustomerConfiguration';

export default class InitialState {
  customerConfig: CustomerConfig | null = null;

  loading = true;

  loadingCount = 0;

  user: User | null = null;

  collections: Collection[] = [];

  forms: Form[] = [];

  lists: List[] = [];

  selectedSite: Site | null = null;

  selectedList: List | null = null;

  communications: Communication[] = [];

  selectedCommunication: Communication | null = null;

  communicationsShowOnlyDisplayed = false;

  theme: Theme = defaultTheme;
}
