
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import WithStateMixin from '../../store/WithStateMixin';

@Component
export default class SiteSelection extends mixins(Vue, WithStateMixin) {
  get site() {
    return this.state.selectedSite;
  }
}
