import { AppConstants } from '../app/app.constants';
import store from '../store';
import Communication from '../store/models/Communication';
import CommunicationLink from '../store/models/CommunicationLink';
import { EslConfig } from '../store/models/EslConfig';
import { EslInfo } from '../store/models/EslInfo';
import Form from '../store/models/Form';
import List from '../store/models/List';
import LoginCredentials from '../store/models/LoginCredentials';
import Site from '../store/models/Site';
import User from '../store/models/User';
import { Mutations } from '../store/mutations';
import axios from './axios';
import axiosRepository from './axios.repository';
import { eslAxios } from './esl.axios';
import { LoginResponse } from './responses/login.response';

export const httpService = {
  async getMyUser() {
    return axios.get<User>('/users/me').then((response) => response.data);
  },

  async searchSites(query: string) {
    return axios.get<Site[]>(`/sites/search/${query}`).then((r) => r.data);
  },

  async loadCollections() {
    return axios.get('/collections').then((response) => response.data);
  },

  async loadSiteById(customerId: string) {
    return axios
      .get<Site>(`/sites/${customerId}`)
      .then((response) => response.data);
  },

  async loadSiteForms(customerId: string) {
    return axios
      .get<Form[]>(`/forms/site/${customerId}`)
      .then((response) => response.data);
  },

  async loadCommunications() {
    return axios.get('/communications').then((response) => response.data);
  },

  async loadCommunicationsLinks(zoneId: string) {
    return axios
      .get<CommunicationLink[]>(`/communications/links/${zoneId}`)
      .then((r) => r.data);
  },

  async loadCommunicationById(id: string) {
    return axios.get(`/communications/${id}`).then((response) => response.data);
  },

  async loadCommunicationsForSite(customerId: string) {
    return axios
      .get(`/communications/site/${customerId}`)
      .then((response) => response.data);
  },

  async loadCommunicationsForList(listId: string) {
    return axios
      .get(`/communications/list/${listId}`)
      .then((response) => response.data);
  },

  async saveCommunication(communication: Communication) {
    return axios
      .post('/communications', communication)
      .then((response) => response.data);
  },

  async updateCommunication(communication: Communication) {
    return axios
      .put(`/communications/${communication._id}`, communication)
      .then((response) => response.data);
  },

  async loadEslConfigs(customerId: string) {
    return axios
      .get<EslConfig[]>(`/esl/site/${customerId}`)
      .then((r) => r.data);
  },

  async getEslInfo(labelCode: string) {
    return eslAxios.get<EslInfo>(`/info/${labelCode}`).then((r) => r.data);
  },

  async getEslImage(labelCode: string) {
    return eslAxios
      .get<Blob>(`/info/image/${labelCode}`, {
        responseType: 'blob',
      })
      .then((r) => r.data);
  },

  async pushPayloadToEsl(eslConfig: EslConfig, payload: any) {
    // form config keys begining with "payload" are added to payload
    const additionalPayload = Object.entries(eslConfig.formConfig).reduce<
      EslConfig['formConfig']
    >((acc, [key, value]) => {
      if (key.startsWith('payload-')) {
        acc[key.replace('payload-', '')] = value;
      }
      return acc;
    }, {});

    return eslAxios.post(`/update`, {
      labelCode: eslConfig.labelCode,
      template: eslConfig.template,
      payload: {
        ...payload,
        ...additionalPayload,
        useCode: eslConfig.useCode,
      },
    });
  },

  async changeEslCloneEnableStatus(labelCode: string, cloneEnable: boolean) {
    return eslAxios
      .patch(`/info/${labelCode}`, {
        cloneEnable,
      })
      .then((r) => r.data);
  },

  async updateEslCloneTargets(labelCode: string, cloneTargets: string[]) {
    return eslAxios
      .patch(`/info/${labelCode}`, {
        cloneTargets,
      })
      .then((r) => r.data);
  },

  async loadLists() {
    return axios.get('/lists').then((response) => response.data);
  },

  async saveList(list: List) {
    return axios.post('/lists', list).then((response) => response.data);
  },

  async updateList(list: List) {
    return axios
      .put(`/lists/${list._id}`, list)
      .then((response) => response.data);
  },

  async login(credentials: LoginCredentials) {
    return axios
      .post<LoginResponse>('/auth/login', {
        email: credentials.email,
        password: credentials.password,
      })
      .then((response) => {
        localStorage.setItem(
          AppConstants.LOCAL_STORAGE_TOKEN_KEY,
          response.data.token,
        );
        return response.data;
      });
    // .then(() => this.getMyUser());
  },

  async logout() {
    return axios.get('/users/auth/logout').then(() => {
      store.commit(Mutations.USER_SET, null);
    });
  },

  async uploadFileRD(formData: FormData, onUploadProgress: Function) {
    return axiosRepository.post('/upload', formData, {
      onUploadProgress: (e) => {
        const percentCompleted = Math.round((e.loaded * 100) / e.total);
        if (typeof onUploadProgress === 'function')
          onUploadProgress(percentCompleted);
      },
    });
  },

  async loadDataset(name: string) {
    return axiosRepository
      .get(`/datasets/${name}`)
      .then((response) => response.data);
  },

  async setDatasetValue(name: string, key: string, value: string | any) {
    return axiosRepository.post(`/datasets/${name}`, {
      [key]: value,
    });
  },
};
