
import HttpMixin from '@/shared/http/http.mixin';
import WithSiteMixin from '@/shared/sites/mixins/with-site.mixin';
import Communication from '@/shared/store/models/Communication';
import CommunicationLink from '@/shared/store/models/CommunicationLink';
import Component, { mixins } from 'vue-class-component';
import BaseForm from '../_BaseForm.vue';

@Component
export default class CommunicationForm extends mixins(
  BaseForm,
  WithSiteMixin,
  HttpMixin,
) {
  links: CommunicationLink[] = [];
  communications: Communication[] = [];

  async mounted() {
    this.emitLoading(true);
    // Chargement des communications actives de la zone (links)
    const zoneId = this.form.config.zoneId;
    if (!zoneId) return (this.errorMsg = 'Bad zoneId');
    if (!this.site) return (this.errorMsg = 'Bad site');
    this.communications = await this.http.loadCommunicationsForSite(
      this.site.customerId,
    );
    this.links = await this.http.loadCommunicationsLinks(zoneId);
    this.emitLoading(false);
  }

  private errorMsg = '';
}
