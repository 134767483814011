
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import Form from '@/shared/store/models/Form';
import WithSiteMixin from '@/shared/sites/mixins/with-site.mixin';
import WithStateMixin from '@/shared/store/WithStateMixin';
import CommunicationForm from './fc/communication-form/CommunicationForm.vue';
import EslForm from './fc/esl-form/EslForm.vue';

const components: { [key: string]: any } = {
  CommunicationForm,
  EslForm,
};

@Component({ props: ['value', 'form'] })
export default class FormDialog extends mixins(
  Vue,
  WithSiteMixin,
  WithStateMixin,
) {
  value!: boolean;
  formLoading = false;
  form!: Form;

  close() {
    this.$emit('input', false);
  }

  changeLoadingState(state: boolean) {
    this.formLoading = state;
  }

  get formComponent() {
    if (this.form?.type && Object.keys(components).includes(this.form.type))
      return components[this.form.type];
  }
}
