export interface EslPayload {
  [key: string]: any;
}

export const parseEslCode = (code: string) => {
  const matches = code.match(/^([A-Z]{1,2})(\d{1,3})$/);
  if (matches) {
    const letters = matches[1];
    const number = Number(matches[2]);
    return {
      letters,
      number,
    };
  }
};
