
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import LoginCredentials from '@shared/store/models/LoginCredentials';
import HttpMixin from '@shared/http/http.mixin';
import { AppEvents } from '../app/app.constants';

@Component
export default class Login extends mixins(Vue, HttpMixin) {
  private credentials: LoginCredentials = {};

  private error = '';

  mounted() {
    if (this.$store.state.authenticated) {
      this.$router.push({ name: 'home' });
    }
  }

  submit(e: Event) {
    this.error = '';
    this.http.login(this.credentials).then(
      () => {
        this.$router.push({ path: '/' });
        this.$root.$emit(AppEvents.REQUEST_BOOSTRAP);
      },
      (reason) => {
        if (reason.response.status === 400) {
          this.error = 'Les identifiants saisis semblent incorrects.';
        } else {
          this.error = `Une erreur inconnue est survenue. (code ${reason.response.status})`;
        }
      },
    );

    e.preventDefault();
  }

  get loading() {
    return this.$store.state.loading;
  }
}
