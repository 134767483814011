import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Login from '../views/Login.vue';
import Support from '../support/views/Support.vue';
import SiteHome from '../sites/views/SiteHome.vue';
import AppError from '../app/components/AppError.vue';
import Marketing from '../marketing/views/Marketing.vue';
import Account from '../account/views/Account.vue';
import store, { loadingService } from '../store';
import { siteTools } from '../sites/site.tools';
import { httpService } from '../http/http.service';
import { Mutations } from '../store/mutations';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/saisie',
  },
  {
    name: 'home_site',
    path: '/saisie',
    beforeEnter: (to, from, next) => {
      const site = store.state.selectedSite;
      if (site) return next(`/saisie/${siteTools.makeSiteSlug(site)}`);
      next();
    },
    component: SiteHome,
    meta: { title: 'MyPlay' },
  },
  {
    name: 'home_site_slug',
    path: '/saisie/:slug',
    component: SiteHome,
    beforeEnter: async (to, from, next) => {
      const site = store.state.selectedSite;
      const customerIdFromSlug = siteTools.parseCustomerIdFromSlug(to.params.slug);
      loadingService.start();
      if (!customerIdFromSlug) return;
      if (!site || customerIdFromSlug !== site.customerId)
        await httpService
          .loadSiteById(customerIdFromSlug)
          .then((site) => store.commit(Mutations.SITE_SELECT, site));
      loadingService.end();
      next();
    },
    meta: { title: 'MyPlay' },
  },
  {
    name: 'marketing',
    path: '/marketing',
    component: Marketing,
    meta: { title: 'Marketing - MyPlay' },
  },
  {
    name: 'assistance',
    path: '/assistance',
    component: Support,
    meta: { title: 'Assistance - MyPlay' },
  },
  {
    name: 'account',
    path: '/moncompte',
    component: Account,
    meta: { title: 'Mon compte - MyPlay' },
  },
  {
    name: 'login',
    path: '/login',
    component: Login,
    meta: { title: 'Connexion - MyPlay' },
  },
  {
    name: 'error',
    path: '/error',
    component: AppError,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) document.title = to.meta.title;
  return next();
});

export default router;
