import Axios from 'axios';
import { AppConstants } from '../app/app.constants';

export const eslAxios = Axios.create({
  baseURL: process.env.VUE_APP_ESL_API_URL,
  withCredentials: true,
});

eslAxios.interceptors.request.use((config) => {
  if (localStorage[AppConstants.LOCAL_STORAGE_TOKEN_KEY])
    config.headers.Authorization = `Bearer ${localStorage.token}`;
  return config;
});
