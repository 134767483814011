
import HttpMixin from '@/shared/http/http.mixin';
import WithSiteMixin from '@/shared/sites/mixins/with-site.mixin';
import { EslConfig } from '@/shared/store/models/EslConfig';
import WithSnackMixin from '@/shared/app/mixins/with-snack.mixin';
import Component, { mixins } from 'vue-class-component';
import BaseForm from '../_BaseForm.vue';
import EslSelectionForm from './extensions/EslSelectionForm.vue';
import EslEditionCard from './extensions/EslEditionCard.vue';
import EslCloningCard from './extensions/EslCloningCard.vue';
import { Watch } from 'vue-property-decorator';
import { EslInfo } from '@/shared/store/models/EslInfo';
import { Nullable } from '@/shared/app/app.utils';
import { SnackType } from '@/shared/app/app.constants';
import { AxiosError } from 'axios';

@Component({
  components: { EslSelectionForm, EslEditionCard, EslCloningCard },
})
export default class EslForm extends mixins(BaseForm, WithSiteMixin, HttpMixin, WithSnackMixin) {
  eslConfigs: EslConfig[] = [];
  eslConfig: Nullable<EslConfig> = null;
  eslInfo: Nullable<EslInfo> = null;

  async activate() {
    this.reset();
    this.emitLoading(true);
    // Chargement des étiquettes de ce site
    await this.loadEslConfigs();
    this.emitLoading(false);
  }

  async loadEslConfigs() {
    if (this.site) this.eslConfigs = await this.http.loadEslConfigs(this.site?.customerId);
  }

  @Watch('eslConfig')
  async selectionWatcher() {
    if (!this.eslConfig) return;
    console.log('eslConfig=', this.eslConfig);
    this.emitLoading(true);
    this.eslInfo = null;
    await this.http.getEslInfo(this.eslConfig.labelCode).then(
      (eslInfo) => (this.eslInfo = eslInfo),
      (e: AxiosError) => {
        this.snack({
          text: e.response?.data.message || `Erreur inconnue (${e.response?.data.statusCode})`,
          type: SnackType.ERROR,
        });
        this.reset();
      },
    );
    this.emitLoading(false);
  }

  get selected() {
    return Boolean(this.eslConfig && this.eslInfo);
  }

  reset() {
    this.eslConfig = null;
    this.eslInfo = null;
  }
}
