
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import HttpMixin from '../../http/http.mixin';
import Site from '../../store/models/Site';

@Component
export default class SiteSelectionDialog extends mixins(Vue, HttpMixin) {
  dialog = false;
  loading = false;
  disabled = false;
  searchInput = '';
  searchTimeout = 0;
  items: Site[] = [];
  selection: Site[] = [];

  @Prop()
  value!: Site[] | Site | string | string[];

  @Prop({ type: Boolean })
  multiple!: boolean;

  @Prop({ type: Boolean })
  returnId!: boolean;

  search() {
    if (!this.searchInput) return (this.items = []);
    clearTimeout(this.searchTimeout);
    this.loading = true;
    this.searchTimeout = Number(
      setTimeout(() => {
        this.http
          .searchSites(this.searchInput)
          .then((sites) => {
            this.items = sites;
          })
          .finally(() => {
            this.loading = false;
          });
      }, 300),
    );
  }

  select(site: Site) {
    if (!this.isSiteSelected(site)) this.selection.push(site);
    this.emitValue();
  }

  unselect(site: Site) {
    this.selection.splice(this.selection.indexOf(site), 1);
    this.emitValue();
  }

  emitValue() {
    if (!this.selection.length) {
      if (this.multiple) this.$emit('input', []);
      else this.$emit('input', undefined);
    } else {
      if (this.returnId) {
        if (!this.multiple) this.$emit('input', this.selection.pop()?.customerId);
        else
          this.$emit(
            'input',
            this.selection.map((s) => s.customerId),
          );
      } else {
        if (!this.multiple) this.$emit('input', this.selection.pop());
        else this.$emit('input', this.selection);
      }
    }
    if (!this.multiple) this.dialog = false;
  }

  // TODO selection devient le model du composant
  // va falloir garder double variable car un fonctionnement par ID possible serait cool

  @Watch('dialog')
  async toggle(opening: boolean) {
    if (opening) {
      this.clear();
      if (this.value) {
        this.disabled = true;
        if (this.returnId) {
          let ids: string[];
          if (this.multiple) ids = this.value as string[];
          else ids = [this.value as string];
          for (const id of ids) {
            await this.http.loadSiteById(id).then((site) => this.selection.push(site));
          }
        } else {
          if (this.multiple) this.selection = this.value as Site[];
          else this.selection = [this.value as Site];
        }
        this.disabled = false;
      }
      this.$nextTick().then(() => {
        if (this.$refs.input) (this.$refs.input as HTMLInputElement).focus();
      });
    }
  }

  isSiteSelected(site: Site) {
    return Boolean(this.selection.find((s) => s._id === site._id));
  }

  clear() {
    this.items = [];
    this.selection = [];
    this.searchInput = '';
  }
}
