import Vue from 'vue';
import App from '@shared/app/components/App.vue';
import router from '@/shared/router';
import store from '@shared/store';
import vueConfiguration from './configuration/vue.configuration';
import momentConfiguration from './configuration/moment.configuration';
import validationConfiguration from './configuration/validation.configuration';
import hooksConfiguration from './configuration/hooks.configuration';
import vuetifyConfiguration from './configuration/vuetify.configuration';
import defaultTheme from './theme/default.theme';
import { Mutations } from '../store/mutations';
import extensionPointsConfiguration from './configuration/extensionPoints.configuration';
import CustomerConfig from './model/CustomerConfiguration';

const main = (config: CustomerConfig) => {
  hooksConfiguration();
  vueConfiguration();
  momentConfiguration();
  validationConfiguration();
  extensionPointsConfiguration(config.extensions || {});

  const vuetify = vuetifyConfiguration(config.theme || defaultTheme);
  store.commit(Mutations.THEME_SET, config.theme || defaultTheme);

  // Ensure httpS in production
  if (process.env.NODE_ENV === 'production' && window.location.protocol !== 'https:') {
    window.location.replace(
      `https:${window.location.href.substring(window.location.protocol.length)}`,
    );
  }

  store.commit(Mutations.CUSTOMER_CONFIG_SET, config);
  const app = new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  });
  app.$mount('#app');
};

export default main;

import(`../../${process.env.VUE_APP_CUSTOMER}/main`).then((mod) => {
  main(mod.default);
});
