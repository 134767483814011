export const AppConstants = {
  LOCAL_STORAGE_TOKEN_KEY: 'token',
  LOCAL_STORAGE_SITE_ID_KEY: 'site',
};

export const AppEvents = {
  REQUEST_BOOSTRAP: 'request-bootstrap',
};

export enum USER_ROLE {
  GOD = 'GOD',
  SUPERADMIN = 'SUPERADMIN',
  ADMIN = 'ADMIN',
  MARKETING = 'MARKETING',
  USER = 'USER',
}

export enum SnackType {
  SUCCESS,
  WARNING,
  ERROR,
  INFO,
}

export interface SnackOptions {
  text: string;
  type?: SnackType;
  timeout?: number;
  color?: string;
  dark?: boolean;
  width?: number;
}
