
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import HttpMixin from '@/shared/http/http.mixin';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import EslSelectionForm from '../extensions/EslSelectionForm.vue';
import { EslConfig } from '@/shared/store/models/EslConfig';

@Component({
  components: { EslSelectionForm },
})
export default class EslSelectionDialog extends mixins(Vue, HttpMixin) {
  @Prop()
  value!: boolean;
  @Prop({ default: () => [] })
  eslConfigs!: EslConfig[];
  disabled = false;

  @Ref('form')
  form!: EslSelectionForm;

  @Watch('value')
  valueWatcher(value: boolean) {
    if (value) this.activate();
  }

  activate() {
    this.reset();
  }

  reset() {
    if (this.form) this.form.clear();
  }

  select(eslConfig: EslConfig) {
    this.$emit('select', eslConfig);
    this.close();
  }

  close() {
    this.$emit('input', false);
  }
}
