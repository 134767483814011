import Vue from 'vue';
import Vuex from 'vuex';
import { Mutations } from './mutations';
import User from './models/User';
import InitialState from './InitialState';
import Collection from './models/Collection';
import Form from './models/Form';
import Site from './models/Site';
import List from './models/List';
import Communication from './models/Communication';
import Theme from '../app/model/Theme';
import CustomerConfig from '../app/model/CustomerConfiguration';
import { AppConstants, USER_ROLE } from '../app/app.constants';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: new InitialState(),
  getters: {
    isAdmin(state) {
      return state.user && state.user.roles.includes(USER_ROLE.ADMIN);
    },
    isMarketing(state) {
      return state.user && state.user.roles.includes(USER_ROLE.MARKETING);
    },
    getFormById: (state) => (formId: string) => state.forms.find((form) => form._id === formId),
    getCollectionById: (state) => (collectionId: string) =>
      state.collections.find((collection) => collection._id === collectionId),
  },
  mutations: {
    [Mutations.CUSTOMER_CONFIG_SET](state, config: CustomerConfig) {
      state.customerConfig = config;
    },
    [Mutations.LOADING](state, loading: boolean) {
      if (loading) state.loadingCount += 1;
      else state.loadingCount -= 1;
      state.loading = state.loadingCount > 0;
    },
    [Mutations.USER_SET](state, user: User) {
      state.user = user;
    },
    [Mutations.SITE_SELECT](state, site: Site) {
      localStorage.setItem(AppConstants.LOCAL_STORAGE_SITE_ID_KEY, site.customerId);
      state.selectedSite = site;
    },
    [Mutations.LISTS_SET](state, lists: List[]) {
      state.lists = lists;
    },
    [Mutations.LIST_SELECT](state, list: List) {
      state.selectedList = list;
    },
    [Mutations.LIST_ADD_OR_UPDATE](state, list: List) {
      if (list._id) {
        state.lists.splice(
          state.lists.findIndex((fList) => fList._id === list._id),
          1,
        );
      }
      state.lists.push(list);
    },
    [Mutations.COMMUNICATIONS_SET](state, communications: Communication[]) {
      state.communications = communications;
    },
    [Mutations.COMMUNICATION_SELECT](state, communication: Communication) {
      state.selectedCommunication = communication;
    },
    [Mutations.COMMUNICATIONS_SHOW_ONLY_DISPLAYED_SET](state, show: boolean) {
      state.communicationsShowOnlyDisplayed = show;
    },
    [Mutations.COLLECTIONS_SET](state, collections: Collection[]) {
      state.collections = collections;
    },
    [Mutations.FORMS_SET](state, forms: Form[]) {
      state.forms = forms;
    },
    [Mutations.THEME_SET](state, theme: Theme) {
      state.theme = theme;
    },
  },
  actions: {},
  modules: {},
  strict: true,
});
export default store;

export const loadingService = {
  start: () => store.commit(Mutations.LOADING, true),
  end: () =>
    setTimeout(() => {
      store.commit(Mutations.LOADING, false);
    }, 150),
};
