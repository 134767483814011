
import WithSiteMixin from '@/shared/sites/mixins/with-site.mixin';
import WithStateMixin from '@/shared/store/WithStateMixin';
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';

@Component
export default class Marketing extends mixins(
  Vue,
  WithSiteMixin,
  WithStateMixin,
) {}
