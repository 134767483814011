import Theme from '../model/Theme';

const defaultTheme: Theme = {
  customerName: 'Impactiv',
  primaryColor: '#e15e15',
  secondaryColor: '#607d8b',
  accentColor: '#ffc107',
  errorColor: '#f44336',
  warningColor: '#ff9800',
  infoColor: '#3f51b5',
  successColor: '#4caf50',
};

export default defaultTheme;
